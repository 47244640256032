import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { IMedia } from '../types/heartcore.types';

type MetaProps = {
	title?:string;
	language?:string;
	description?:string;
	keywords?:string[];
	ogTitle?:string;
	ogDescription?:string;
	ogImage?:IMedia;
	ogUrl?:string;
	ogType?:string;
}

const Meta = ({
	title,
	language,
	description,
	keywords,
	ogTitle,
	ogDescription,
	ogImage,
	ogUrl,
	ogType,
}: MetaProps) => {

	const { globalContent: { metaData: data } } = useStaticQuery(graphql`
		{
			globalContent {
				metaData {
					description
					documentTitle
					keywords
					lang
					ogDescription
					ogTitle
					ogImage {
						url
					}
					ogUrl
					ogType
				}
			}
		}
	`);

	const lang = language || data.lang || 'da';
	const metaTitle = title || data.documentTitle;
	const metaDescription = description || data.description;
	const metaKeywords = keywords || data.keywords;
	const openGraphTitle = ogTitle || data.ogTitle || data.documentTitle;
	const openGraphDescription = ogDescription || data.ogDescription; 
	const openGraphImage = ogImage || data.ogImage; 
	const openGraphUrl = ogUrl || data.ogUrl; 
	const openGraphType = ogType || data.ogType; 
	const defaultTitle = data.documentTitle;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			bodyAttributes={{
				class: 'body',
			}}
			title={metaTitle}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: 'description',
					content: metaDescription,
				},
				{
					name: 'keywords',
					content: metaKeywords.join(', '),
				},
				{
					property: 'og:title',
					content: openGraphTitle,
				},
				{
					property: 'og:description',
					content: openGraphDescription,
				},
				{
					property: 'og:type',
					content: openGraphType || 'website',
				},
				{
					property: 'og:image',
					content: openGraphImage?._url,
				},
				{
					property: 'og:url',
					content: openGraphUrl,
				},
			]}
		>
			<script id="CookieConsent" src="https://policy.app.cookieinformation.com/uc.js" data-culture="DA" type="text/javascript"></script>
			<script async src="https://www.googletagmanager.com/gtag/js?id=G-DVJ9XKV67J"></script>
			<script dangerouslySetInnerHTML={{ __html: 'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'G-DVJ9XKV67J\');' }}></script>
		</Helmet>
	);
};

export default Meta;